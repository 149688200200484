define("mgw/components/edit-project", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    sortedActiveUsers: Ember.computed('model.users', function () {
      return this.get('model.users').reject(function (user) {
        return user.get('invited') || user.get('inactive');
      }).sortBy('id');
    }),
    customerSortKey: ['name'],
    sortedCustomers: Ember.computed.sort('model.customers', 'customerSortKey'),
    actions: {
      submit: function submit() {
        var _this = this;

        var project = this.get("model.project");
        var featureList = this.get("model.projectStats.features");
        project.set("features", featureList);
        project.save().then(function () {
          _this.close();
        }, function (response) {
          window.alert("Unable to Updated project reason: ".concat(response.errors[0].detail));

          _this.close();
        });
      },
      toggleFeature: function toggleFeature(feature) {
        var features = this.get("model.projectStats.features");

        if (features.includes(feature)) {
          features.removeObject(feature);
        } else {
          features.pushObject(feature);
        }
      },
      toggleProjectState: function toggleProjectState(state) {
        var _this2 = this;

        var project = this.get("model.project");
        var type = state ? "Delete" : "Restore";
        project.set("isInactive", state);
        project.save().then(function () {
          _this2.set("model.projectStats.deletedAt", project.get("deletedAt"));

          window.alert("Project ".concat(type, " was successful"));
        }, function (response) {
          window.alert("Unable to ".concat(type, " project"));
          console.error(response);
        });
      },
      toggleProjectHibernated: function toggleProjectHibernated(state) {
        var _this3 = this;

        var project = this.get("model.project");
        var type = state ? "Hibernate" : "Wakeup";
        project.set("isHibernated", state);
        project.save().then(function () {
          _this3.set("model.projectStats.isHibernated", project.get("isHibernated"));

          window.alert("Project ".concat(type, " was successful"));
        }, function (response) {
          window.alert("Unable to ".concat(type, " project"));
          console.error(response);
        });
      },
      onOwnerChange: function onOwnerChange(userID) {
        var project = this.get("model.project");
        this.store.findRecord("user", userID).then(function (user) {
          return project.set("owner", user);
        }).catch(function (err) {
          window.alert("Unable to find new owner: ".concat(userID));
          console.error(err);
        });
      },
      onCustomerChange: function onCustomerChange(newCustomerID) {
        if (newCustomerID == "none") {
          this.set("model.project.customer", null);
        } else {
          var newCustomer = this.store.peekRecord("customer", newCustomerID);
          this.set("model.project.customer", newCustomer);
        }
      },
      cancel: function cancel() {
        this.get("model.project").rollbackAttributes();
        this.close();
      }
    }
  });

  _exports.default = _default;
});